/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useState } from 'react'

import FaqQuestion from '../components/FaqQuestion'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../less/cms.less'
import UtmContent from '../models/UtmContent'

const Faq = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState('')

  const toggle = (identifier: string) => {
    setOpen(open === identifier ? '' : identifier)
  }

  return (
    <Layout>
      <SEO title="FAQs" />
      <div className="cms-container">
        <h1 className="cms-container__title">Frequently Asked Questions</h1>
        <div className="questions-container cms-container__wrapper">
          <FaqQuestion
            customClass="question-group--first"
            question="What is MealPal?"
            open={open === '#1'}
            toggle={() => toggle('#1')}
            utmContent={new UtmContent({ position: 'what-is-mp' }).toString()}
          >
            <p>
              {t('mealpal-name')} offers lunch and dinner subscriptions giving you access to the best restaurants for
              less than $6 per meal. We provide a fast, fun, and efficient system to get you delicious and affordable
              meals every day.
            </p>
            <p>
              MealPal members can reserve meals from any participating restaurant. Each restaurant offers meals daily
              for lunch. If a member is in a city that offers dinner, and has a lunch and dinner plan, both lunch and
              dinner can be reserved daily.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="What are the membership plans and how much do they cost?"
            open={open === '#2'}
            toggle={() => toggle('#2')}
            utmContent={new UtmContent({ position: 'membership-plans' }).toString()}
          >
            <p>
              <strong>MealPal offers various 30-day lunch subscriptions, including:</strong>
              <br />
              20 Meal Lunch: Up to 20 meals per 30-day cycle, $5.99 per meal.
              <br />
              12 Meal Lunch: Up to 12 meals per 30-day cycle, $6.39 per meal.
              <br />
              12 Meal Lunch w/ Rollover: 12 meals per 30-day cycle, $6.99 per meal. Up to 12 meals can be rolled over
              for the following 30 days provided you start a new paid cycle.
            </p>
            <p>
              <strong>In New York City, 30-day credit lunch subscriptions are available, including:</strong>
              <br />
              16 to 20 Meal Lunch for as little as $3.48 per meal. With this plan you get 120 credits for $139.
              <br />
              10 to 12 Meal Lunch for as little as $3.81 per meal. With this plan you get 70 credits for $89.
              <br />
              <br />
              <strong>In San Francisco and Seattle, 30-day credit lunch subscriptions are available, including:</strong>
              <br />
              16 to 20 Meal Lunch for as little as $3.48 per meal. With this plan you get 120 credits for $139.
              <br />
              10 to 12 Meal Lunch for as little as $3.81 per meal. With this plan you get 70 credits for $89.
              <br />
              <br />
              <strong>In New York City, 30-day dinner subscriptions are available, including:</strong>
              <br />
              12 Meal Dinner: 60 credits per 30-day cycle, as low as $5.67 per meal.
              <br />
              20 Meal Dinner: 105 credits per 30-day cycle, as low as $5.30 per meal.
              <br />
              <br />
              Meals are available for between 3 and 14 credits, depending on the retail price of the meal. Unless
              otherwise noted, plans last for a 30 day cycle and automatically renew after this time or when you have
              less than 10 credits remaining, whichever comes first. You can rollover your unused credits to your next
              continuous cycle. The maximum number of credits you can rollover is equal to the number of credits on the
              plan you are renewing to.
            </p>
            <p>
              All subscription plans are subject to tax and fees. The rate for tax and fees vary by city, and usually
              matches the local tax rate. Rates are as follows: Austin-8.25%; Boston-7%; Chicago-11.5%; Los
              Angeles-9.5%; NYC-8.875%; Philadelphia-8%; Portland-0%; San Francisco-9.5%; Seattle-10.25%; Washington,
              DC-10%.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="How do I use MealPal?"
            open={open === '#3'}
            toggle={() => toggle('#3')}
            utmContent={new UtmContent({ position: 'how-to-use' }).toString()}
          >
            <p>
              <strong>For lunch:</strong>
              <br />
              After subscribing to one of our lunch plans you will be able to start reserving lunch. The MealPal lunch
              kitchen opens daily at 5:00 PM, local time. The lunch kitchen closes at 1:30 PM the next day. Log in while
              the lunch kitchen is open to reserve your lunch. Participating restaurants offer daily lunch options. You
              can reserve up to one lunch item per day. If you wish to change your lunch reservation to a different
              meal, you can cancel your reservation and select another meal as long as you do this in advance of 10:30
              AM.
            </p>
            <p>
              <strong>For dinner:</strong>
              <br />
              Subscribing to one of our dinner plans (available in select locations) will give you access to our dinner
              kitchen. The kitchen opens at 5:00 PM on the day before you will pick up your dinner. The dinner kitchen
              closes at 4:00 PM the following day - the day of pickup. Log in while the dinner kitchen is open to
              reserve your dinner. Participating restaurants offer daily dinner options. If you have a 15 Meal Dinner or
              10 Meal Dinner plan then you can reserve up to one dinner item per day. If you have an 8 Meal Dinner
              Rollover plan then you can reserve multiple dinner items per day. If you wish to change your dinner
              reservation to a different meal, you can cancel your reservation and select another meal as long as you do
              this in advance of the dinner kitchen closing at 4:00 PM.
            </p>
            <p>
              <strong>For Lunch & Dinner Plans:</strong>
              <br />
              You may have a lunch plan, or a dinner plan, or both! Same cancellation rules apply.
            </p>
            <p>
              When browsing available meals, note that each meal is shown with a list of ingredients and a photo of the
              actual meal. To reserve your meal, tap on the meal image, select your pickup time, and tap reserve.
            </p>
            <p>
              Your meal will be ready and waiting for you at your designated pick up time. Skip the line, tell the
              restaurant you are with MealPal and scan to pick up. Enjoy your meal!
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="Where can I use MealPal?"
            open={open === '#4'}
            toggle={() => toggle('#4')}
            utmContent={new UtmContent({ position: 'where-to-use' }).toString()}
          >
            <p>
              You can get meals at any participating restaurant in the MealPal network. To see a list and map of all
              participating restaurants go to{' '}
              <a
                target="_blank"
                href={`${process.env.GATSBY_LANDING_PAGE_URL}/#map`}
                rel="noreferrer"
              >{`${process.env.GATSBY_LANDING_PAGE_URL}/#map`}</a>
              .
            </p>
            <p>
              MealPal lunch is currently available in Auckland, Austin, Boston, Chicago, London, Los Angeles,
              Manchester, Melbourne, New York City, Philadelphia, Portland, San Francisco, Seattle, Singapore, Sydney,
              Toronto, Washington, D.C.
            </p>
            <p>
              MealPal dinner is currently available in New York City, and Singapore. Interested in dinner in your city?
              Let us know and we’ll be there soon!
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="When is MealPal available?"
            open={open === '#5'}
            toggle={() => toggle('#5')}
            utmContent={new UtmContent({ position: 'when-available' }).toString()}
          >
            <p>
              MealPal is available weekdays except on the following holidays: New Year’s Day, Memorial Day, July 4th,
              Labor Day, Thanksgiving Day, the day after Thanksgiving, and Christmas Day.
            </p>
            <p>
              In cases where a holiday falls on a weekend, it is possible that MealPal will observe the holiday on the
              closest weekday.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="What does my plan cover?"
            open={open === '#6'}
            toggle={() => toggle('#6')}
            utmContent={new UtmContent({ position: 'what-does-plan-cover' }).toString()}
          >
            <p>
              Your lunch subscription covers 100% of the cost of your lunch, but does not cover dinner. There are no
              additional charges when you pick up your lunch.
            </p>
            <p>
              Your dinner subscription covers 100% of the cost of your dinner, but does not cover lunch. As with lunch,
              there are no additional charges when you pick up your dinner.
            </p>
            <p>To enjoy both lunch and dinner (if available in your area), you must have a lunch and a dinner plan.</p>
          </FaqQuestion>

          <FaqQuestion
            question="When does my MealPal start and end?"
            open={open === '#7'}
            toggle={() => toggle('#7')}
            utmContent={new UtmContent({ position: 'start-and-end' }).toString()}
          >
            <p>
              Your MealPal cycle begins the day you sign up for a plan. If you are on a rollover meal plan, your cycle
              will renew every 30 days or upon the use of all meals, whichever comes first. If you are on a credits
              rollover plan, your cycle will renew every 30 days or when you have less than 10 credits remaining,
              whichever comes first .
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="How do I pickup my meal?"
            open={open === '#8'}
            toggle={() => toggle('#8')}
            utmContent={new UtmContent({ position: 'pickup-meal' }).toString()}
          >
            <p>
              In order to pickup your meal, you need to have either the{' '}
              <a
                href="https://apps.apple.com/us/app/mealpal-best-meals-around-you/id1123086545"
                target="_blank"
                rel="noreferrer"
              >
                iOS app
              </a>{' '}
              or the{' '}
              <a
                href="https://play.google.com/store/apps/details?id=com.mealpass.mealpal&hl=us"
                target="_blank"
                rel="noreferrer"
              >
                Android app
              </a>{' '}
              installed on your mobile device.
            </p>
            <p>
              Once at the restaurant, you will scan a QR code and show the restaurant your confirmation screen. This
              screen will expire after 3 minutes, so make sure to scan the QR code just before showing the restaurant
              your confirmation.
            </p>
            <p>You must have a mobile device with a working camera and the MealPal app in order to pickup your meal.</p>
          </FaqQuestion>
          <FaqQuestion
            question="Does MealPal deliver?"
            open={open === '#9'}
            toggle={() => toggle('#9')}
            utmContent={new UtmContent({ position: 'delivery' }).toString()}
          >
            <p>
              Meals reserved on MealPal are for pick up only. With hundreds of restaurants on the platform, you’ll find
              plenty of options in your neighborhood. Plus, a quick walk will help build up your appetite!
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="Can I leave a tip for a restaurant?"
            open={open === '#10'}
            toggle={() => toggle('#10')}
            utmContent={new UtmContent({ position: 'tip-restaurant' }).toString()}
          >
            <p>
              Your MealPal subscription covers all costs. Tipping is not required or expected. If a restaurant goes
              above and beyond and you would like to leave them a tip, you will have the option to do so in the app.
              100% of tips goes to the restaurant!
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="How do I cancel my account?"
            open={open === '#11'}
            toggle={() => toggle('#11')}
            utmContent={new UtmContent({ position: 'cancel-account' }).toString()}
          >
            <p>
              You can make changes in the "My Account" section of the MealPal website or app. You may change your plan,
              add more meals, pause your account, or cancel your membership. You can also make changes by contacting us
              at <a href="mailto:hello@mealpal.com">hello@mealpal.com</a>.
            </p>
            <p>
              Please note that all changes to your membership must be submitted at least seven business days prior to
              the start date of your next 30-day cycle.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="Can I pause or hold my account?"
            open={open === '#12'}
            toggle={() => toggle('#12')}
            utmContent={new UtmContent({ position: 'hold-account' }).toString()}
          >
            <p>
              Yes. In "My Account", click "change plan for next cycle" and follow steps for "hold my account." You can
              also email <a href="mailto:hello@mealpal.com">hello@mealpal.com</a> to pause your account. You can put
              your account on hold for up to three months.
            </p>
            <p>
              The hold will start at the end of your current cycle. You can remove the hold or change your end date at
              any time.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="How can I change my password?"
            open={open === '#13'}
            toggle={() => toggle('#13')}
            utmContent={new UtmContent({ position: 'change-password' }).toString()}
          >
            <p>
              Log out of your account and go to <a href={`${process.env.GATSBY_SERVER_URL}/login`}>the log in screen</a>
              . Then, select the “Forgot?” button to updateyour password.
            </p>
          </FaqQuestion>
          <FaqQuestion
            question="Can I give MealPal as a gift?"
            open={open === '#14'}
            toggle={() => toggle('#14')}
            utmContent={new UtmContent({ position: 'gift-mp' }).toString()}
          >
            <p>
              Yes! Please email us at <a href="mailto:hello@mealpal.com">hello@mealpal.com</a> and we can help set you
              up with a gift account.
            </p>
          </FaqQuestion>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Faq
